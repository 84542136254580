import dayjs from "dayjs";
import "./Calendar.scss";
const weekday = require("dayjs/plugin/weekday");
const weekOfYear = require("dayjs/plugin/weekOfYear");

dayjs.extend(weekday);
dayjs.extend(weekOfYear);

const _locale = {
  monday: "Mon",
  tuesday: "Tue",
  wednesday: "Wed",
  thursday: "Thu",
  friday: "Fri",
  saturday: "Sat",
  sunday: "Sun",
  january: "Jan",
  february: "Feb",
  march: "Mar",
  april: "Apr",
  may: "May",
  june: "Jun",
  july: "Jul",
  august: "Aug",
  september: "Sep",
  october: "Oct",
  november: "Nov",
  december: "Dec",
  today: "Today",
};

export default function Calendar(data) {
  let { target, date, onDate, locale = _locale } = data;

  if (Go.is(target, "String")) {
    target = document.querySelector(target);
  }

  target.innerHTML = `
  <div class="calendar-month">
    <section class="calendar-header">
      <section class="calendar-month-header">
        <div
          id="selected-month"
          class="calendar-month-header-selected-month app-title"
        ></div>
        <section class="calendar-month-header-selectors">
          <span id="previous-month-selector">
            <go-icon name="chevron-left"></go-icon>
          </span>
          <span id="present-month-selector">${locale["today"]}</span>
          <span id="next-month-selector">
            <go-icon name="chevron-right"></go-icon>
          </span>
        </section>
      </section>
      
      <ol
      id="days-of-week"
      class="day-of-week"
      /></ol>
      
    </section>

    <ol
      id="calendar-days"
      class="days-grid"
    >
    </ol>
  </div>
  `;

  const WEEKDAYS = [
    locale["monday"],
    locale["tuesday"],
    locale["wednesday"],
    locale["thursday"],
    locale["friday"],
    locale["saturday"],
    locale["sunday"],
  ];

  const TODAY = dayjs().format("YYYY-MM-DD");

  let INITIAL_YEAR = dayjs(date).format("YYYY");
  let INITIAL_MONTH = dayjs(date).format("MM");
  let INITIAL_DAY = dayjs(date).format("DD");

  let selectedMonth = dayjs(new Date(INITIAL_YEAR, INITIAL_MONTH - 1, 1));
  let currentMonthDays;
  let previousMonthDays;
  let nextMonthDays;

  const daysOfWeekElement = document.getElementById("days-of-week");

  WEEKDAYS.forEach((weekday) => {
    const weekDayElement = document.createElement("li");
    daysOfWeekElement.appendChild(weekDayElement);
    weekDayElement.innerText = weekday;
  });

  createCalendar();
  initMonthSelectors();

  function createCalendar(year = INITIAL_YEAR, month = INITIAL_MONTH) {
    const calendarDaysElement = document.getElementById("calendar-days");

    document.getElementById("selected-month").innerText = dayjs(new Date(year, month - 1)).format("MMMM YYYY");

    removeAllDayElements(calendarDaysElement);

    currentMonthDays = createDaysForCurrentMonth(year, month, dayjs(`${year}-${month}-01`).daysInMonth());

    previousMonthDays = createDaysForPreviousMonth(year, month);

    nextMonthDays = createDaysForNextMonth(year, month);

    const days = [...previousMonthDays, ...currentMonthDays, ...nextMonthDays];

    days.forEach((day) => {
      appendDay(day, calendarDaysElement);
    });
  }

  function appendDay(day, calendarDaysElement) {
    const dayElement = document.createElement("li");
    const dayElementClassList = dayElement.classList;
    dayElementClassList.add("calendar-day");
    const dayOfMonthElement = document.createElement("span");
    dayOfMonthElement.innerText = day.dayOfMonth;
    dayElement.appendChild(dayOfMonthElement);
    calendarDaysElement.appendChild(dayElement);
    dayElement.setAttribute("day", day.dayOfMonth);

    if (!day.isCurrentMonth) {
      dayElementClassList.add("calendar-day--not-current");
    }

    if (day.date === TODAY) {
      dayElementClassList.add("calendar-day--today");
    }

    let isSlectedDay = Number(day.dayOfMonth) === Number(INITIAL_DAY);
    isSlectedDay &&= String(day.date) === String(selectedDate());

    if (isSlectedDay) {
      dayElementClassList.add("calendar-day--selected");
    }

    dayElement.onclick = () => {
      dayClicked(day, dayElement);
    };
  }

  function selectedDate(year = INITIAL_YEAR, month = INITIAL_MONTH, day = INITIAL_DAY) {
    return dayjs(`${year}-${month}-${day}`).format("YYYY-MM-DD");
  }

  function setDate(date) {
    INITIAL_YEAR = dayjs(date).format("YYYY");
    INITIAL_MONTH = dayjs(date).format("MM");
    INITIAL_DAY = dayjs(date).format("DD");

    if (Go.is(onDate, "Function")) {
      onDate(date);
    }
  }

  function dayClicked(day, el) {
    let { date } = day;

    setDate(date);

    Go.uniqueClass("calendar-day--selected", el);
  }

  function removeAllDayElements(calendarDaysElement) {
    let first = calendarDaysElement.firstElementChild;

    while (first) {
      first.remove();
      first = calendarDaysElement.firstElementChild;
    }
  }

  function getNumberOfDaysInMonth(year, month) {
    return dayjs(`${year}-${month}-01`).daysInMonth();
  }

  function createDaysForCurrentMonth(year, month) {
    return [...Array(getNumberOfDaysInMonth(year, month))].map((day, index) => {
      return {
        date: dayjs(`${year}-${month}-${index + 1}`).format("YYYY-MM-DD"),
        dayOfMonth: index + 1,
        isCurrentMonth: true,
      };
    });
  }

  function createDaysForPreviousMonth(year, month) {
    const firstDayOfTheMonthWeekday = getWeekday(currentMonthDays[0].date);

    const previousMonth = dayjs(`${year}-${month}-01`).subtract(1, "month");

    // Cover first day of the month being sunday (firstDayOfTheMonthWeekday === 0)
    const visibleNumberOfDaysFromPreviousMonth = firstDayOfTheMonthWeekday ? firstDayOfTheMonthWeekday - 1 : 6;

    const previousMonthLastMondayDayOfMonth = dayjs(currentMonthDays[0].date)
      .subtract(visibleNumberOfDaysFromPreviousMonth, "day")
      .date();

    return [...Array(visibleNumberOfDaysFromPreviousMonth)].map((day, index) => {
      return {
        date: dayjs(`${previousMonth.year()}-${previousMonth.month() + 1}-${previousMonthLastMondayDayOfMonth + index}`).format("YYYY-MM-DD"),
        dayOfMonth: previousMonthLastMondayDayOfMonth + index,
        isCurrentMonth: false,
      };
    });
  }

  function createDaysForNextMonth(year, month) {
    const lastDayOfTheMonthWeekday = getWeekday(`${year}-${month}-${currentMonthDays.length}`);

    const nextMonth = dayjs(`${year}-${month}-01`).add(1, "month");

    const visibleNumberOfDaysFromNextMonth = lastDayOfTheMonthWeekday ? 7 - lastDayOfTheMonthWeekday : lastDayOfTheMonthWeekday;

    return [...Array(visibleNumberOfDaysFromNextMonth)].map((day, index) => {
      return {
        date: dayjs(`${nextMonth.year()}-${nextMonth.month() + 1}-${index + 1}`).format("YYYY-MM-DD"),
        dayOfMonth: index + 1,
        isCurrentMonth: false,
      };
    });
  }

  function getWeekday(date) {
    return dayjs(date).weekday();
  }

  function initMonthSelectors() {
    document.getElementById("previous-month-selector").addEventListener("click", function() {
      selectedMonth = dayjs(selectedMonth).subtract(1, "month");
      createCalendar(selectedMonth.format("YYYY"), selectedMonth.format("M"));
    });

    document.getElementById("present-month-selector").addEventListener("click", function() {
      const currentMonth = dayjs();
      setDate(currentMonth.format("YYYY-MM-DD"));
      createCalendar(currentMonth.format("YYYY"), currentMonth.format("M"));
    });

    document.getElementById("next-month-selector").addEventListener("click", function() {
      selectedMonth = dayjs(selectedMonth).add(1, "month");
      createCalendar(selectedMonth.format("YYYY"), selectedMonth.format("M"));
    });
  }
}
