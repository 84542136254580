<template>
  <label class="Calendar">
    <div class="CalendarBody"></div>
    <CalendarShortcuts :modal="modal" :onShorcut="onShorcut" v-if="onShorcut" />
    <div class="CalendarOptions">
      <div class="CalendarOptionsSide info">
        <LargeButton :label="date_locale(current)" icon="dot" />
      </div>
      <div class="CalendarOptionsSide">
        <a class="primary-button button" @click="apply">{{ $locale["apply"] }}</a>
      </div>
    </div>
    <Spacer v-if="$isMobile" num="3" />
  </label>
</template>

<script>
import Calendar from "./Calendar";
export default {
  props: ["date", "_class", "single", "onChange", "modal", "onApply", "onShorcut"],
  components: {
    CalendarShortcuts: () => import("./CalendarShortcuts.vue"),
  },
  data: function() {
    return {
      target: null,
      current: this.date,
      id: Go.uuid(),
      monthHandler: null,
    };
  },
  methods: {
    opened: async function() {
      this.target = await Go.waitForElement(".Calendar .CalendarBody");
      if (this.target) {
        this.setCalendar();
      }
    },
    setCalendar: async function() {
      if (this.target) {
        Calendar({ target: this.target, date: this.current, onDate: this.onDate });
        this.monthHandler = await Go.awaitUntilElement("#selected-month");

        if (this.monthHandler) {
          this.monthHandler.onclick = () => {
            this.selectCalendarYear();
          };
        }
      }
    },
    onDate: function(date) {
      this.current = date;
      this.$emit("input", this.current);
    },
    apply: function() {
      this.$emit("apply", this.current);

      if (this.modal && Go.is(this.modal.close, "Function")) {
        this.modal.close();
      }

      if (Go.is(this.onApply, "Function")) {
        this.onApply(this.current);
      }
    },
    selectCalendarYear: function() {
      const self = this;
      this.view({
        title: this.$locale["select_year"],
        component: () => import("@/components/widgets/CalendarYears.vue"),
        class: "menu bottom-mbl",
        closeOutside: true,
        cardStyle: {
          "min-height": "calc(var(--screen-height)/4)",
          "max-height": "calc(var(--screen-height) - calc(var(--header-height) * 2))",
        },
        date: this.current,
        animation: "bottomIn",
        onApply: async function(date) {
          self.current = date;
          self.setCalendar();
        },
      });
    },
  },
  mounted: function() {
    this.opened();
  },
};
</script>

<style lang="scss">
.Calendar {
  position: relative;
  display: block;

  .CalendarPeriod .LargeButtonIcon {
    opacity: 0.5;
  }

  .CalendarPeriod .current {
    color: $primary_color;
    .LargeButtonLabel {
      font-family: $third_font_bold;
    }
    .LargeButtonIcon {
      opacity: 1;
    }
  }

  .CalendarOptions {
    padding: $mpadding;
    background-color: var(--theme-background);
    border-top: solid 1px $alto;
    display: flex;
    justify-content: space-between;
    position: sticky;
    bottom: 0;
    gap: $mpadding;
  }

  .CalendarOptions .button {
    padding: $mpadding/1.5 $mpadding * 1.5;
  }

  .CalendarOptionsSide.info {
    .LargeButton {
      padding: $mpadding/1.5 0;
      pointer-events: none;
    }

    .LargeButtonLabel {
      font-family: $third_font_bold;
    }

    .LargeButtonIcon {
      color: $primary_color;
    }

    .LargeButton .line,
    .LargeButton:after {
      display: none;
    }
  }

  @include screen($tablet_width) {
    .CalendarBody {
      position: relative;
    }
  }
}
</style>
